import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
//import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

import { useKeenSlider } from 'keen-slider-updated/react';
import ArrowNext from '../Configs/ArrowNext';
import ArrowPrev from '../Configs/ArrowPrev';
import Dots from '../Configs/Dots';
import CustomeDot from '../Configs/CustomeDot';
import IconButtonFilled from '../../../components/Layouts/MaterialUi/IconButtonFilled/IconButtonFilled';
import ProjectArrow from '../Configs/ProjectArrow';

interface Props {
  settings?: any;
  children: React.ReactNode;
  dots?: boolean;
  arrows?: boolean;
  projectArrow?: any;
  arrowWeekliySession?: boolean;
  dotData?: any;
  moveToIndex?: number;
}

const KeenSlider = ({
  settings,
  children,
  dots,
  arrows,
  arrowWeekliySession,
  dotData,
  projectArrow,
  moveToIndex = 1,
}: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [pause, setPause] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    ...settings,
    initial: 0,

    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  return (
    <>
      <div className="relative">
        {loaded && instanceRef.current && arrowWeekliySession && (
          <Box sx={{ position: 'absolute', top: -50, right: 0 }}>
            <IconButtonFilled
              sx={{ mr: 3 }}
              disabled={currentSlide === 0}
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
            >
              <ChevronLeft />
            </IconButtonFilled>
            <IconButtonFilled
              disabled={
                currentSlide ===
                instanceRef.current.track.details?.slides.length - 1
              }
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
            >
              <ChevronRight />
            </IconButtonFilled>
          </Box>
        )}
        {dotData && (
          <CustomeDot
            instanceRef={instanceRef}
            currentSlide={currentSlide}
            dotData={dotData}
          />
        )}

        <div ref={sliderRef as any} className="keen-slider">
          {children}
        </div>

        {loaded && instanceRef.current && projectArrow && (
          <>
            <ProjectArrow
              left
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <ProjectArrow
              onClick={(e: any) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}

        {loaded && arrows && instanceRef.current && (
          <>
            <ArrowPrev
              left
              onClick={(e) =>
                e.stopPropagation() ||
                instanceRef.current?.moveToIdx(
                  (instanceRef.current?.track.details.rel - moveToIndex) %
                    instanceRef.current?.track.details.slides.length,
                )
              }
              disabled={currentSlide === 0}
            />
            <ArrowNext
              onClick={(e) =>
                e.stopPropagation() ||
                instanceRef.current?.moveToIdx(
                  (instanceRef.current?.track.details.rel + moveToIndex) %
                    instanceRef.current?.track.details.slides.length,
                )
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}
      </div>
      {loaded && instanceRef.current && dots && (
        <Dots instanceRef={instanceRef} currentSlide={currentSlide} />
      )}
    </>
  );
};

export default KeenSlider;
