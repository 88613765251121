import { makeStyles } from '@mui/styles';
import React from 'react';

const CustomeDot = ({ dotData, currentSlide, instanceRef }) => {
  const classes = useStyle();

  return (
    <div className={classes.optionsContainer}>
      {dotData &&
        dotData.map((data, idx) => (
          <button
            className={` ${currentSlide === idx ? classes.active : ''} ${
              classes.options
            }`}
            onClick={() => {
              instanceRef.current?.moveToIdx(idx);
            }}
            key={idx}
          >
            {data}
          </button>
        ))}
    </div>
  );
};

const useStyle = makeStyles((theme: any) => ({
  optionsContainer: {
    display: 'flex',
    margin: 'auto ',
    justifyContent: 'center',
    borderRadius: 8,
    overflow: 'hidden',
  },
  options: {
    width: '100%',
    padding: '15px',
    fontSize: '14px',
    position: 'relative',
  },
  active: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '0',
      width: '100%',
      bottom: '0',
      height: '4px',
      backgroundColor: '#fe5a35',
      borderRadius: '8px',
    },
  },
  [theme.breakpoints.up('sm')]: {
    options: { fontSize: '24px' },
  },
}));

export default CustomeDot;
