import React, { ReactNode } from 'react';
import { IconButton } from '@mui/material';

interface IconButtonFilled {
  children?: any;
  sx?: any;
  bgColor?: string;
  color?: string;
  onClick: any;
  disabled?: boolean;
}

const IconButtonFilled = ({
  children,
  sx,
  bgColor,
  color,
  onClick,
  disabled,
}: IconButtonFilled) => {
  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      sx={{
        background: bgColor || '#7F56D9',
        color: color || '#fff',
        '&:hover': {
          backgroundColor: bgColor || '#6941C6',
          color: color || '#fff',
        },
        '&:focus': {
          backgroundColor: bgColor || 'rgba(127, 86, 217, 0.12)',
          color: color || '#fff',
        },
        ...sx,
      }}
    >
      {children}
    </IconButton>
  );
};

export default IconButtonFilled;
