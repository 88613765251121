import { makeStyles } from '@mui/styles';
import React from 'react';

const Dots = ({ currentSlide, instanceRef }) => {
  const classes = useStyle();

  const sliceLogic = () => {
    if (instanceRef.current.options.loop) {
      return instanceRef.current.track.details.slides.length;
    } else {
      if (typeof instanceRef.current.options.slides?.perView === 'function') {
        return (
          instanceRef.current.track.details.slides.length -
          Math.floor(instanceRef.current.options?.slides.perView()) +
          1
        );
      } else {
        return (
          instanceRef.current.track.details.slides.length -
          instanceRef.current.options.slides?.perView +
          1
        );
      }
    }
  };

  return (
    <div className={classes.dots}>
      {[
        ...(Array(
          instanceRef.current.track.details.slides.length,
        ).keys() as any),
      ]
        .slice(0, sliceLogic())
        .map((idx) => {
          return (
            <button
              key={idx}
              onClick={() => {
                instanceRef.current?.moveToIdx(idx);
              }}
              className={`${classes.dot} ${
                currentSlide === idx ? classes.active : ''
              }
            `}
            />
          );
        })}
    </div>
  );
};

const useStyle = makeStyles({
  dots: { display: 'flex', padding: '10px 0', justifyContent: 'center' },
  dot: {
    width: '15px',
    height: '15px',
    background: '#fff',
    border: '1px solid #c5c5c5',
    borderRadius: '50%',
    margin: '0 5px',
    padding: '5px',
    cursor: 'pointer',
    '&:focus': { outline: 'none' },
  },
  active: { background: '#602afa', border: '1px solid #602afa' },
});

export default Dots;
