import React from 'react';
import { Box } from '@mui/material';

const ProjectArrow = (props: {
  disabled: boolean;
  left?: boolean;
  onClick: (e: any) => void;
}) => {
  return (
    <Box
      component={'svg'}
      onClick={props.onClick}
      sx={{
        ...arrow,
        ...(props.left ? arrowLeft : arrowRight),
        ...(props.disabled ? disabled : ''),
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </Box>
  );
};
export default ProjectArrow;

const arrow = {
  width: '30px',
  height: '30px',
  position: 'absolute',
  top: '45%',
  transform: 'translateY(-50%)',
  webkitTransform: 'translateY(-50%)',
  fill: '#fff',
  cursor: 'pointer',
};

const arrowLeft = {
  left: '5px',
};

const arrowRight = {
  left: 'auto',
  right: '5px',
};

const disabled = {
  fill: 'rgba(255, 255, 255, 0.5)',
};
