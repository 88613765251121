import React from 'react';
import { ChevronLeft } from '@mui/icons-material';

import style from './style';

const ArrowPrev = (props) => {
  const classes = style();
  const { className, onClick } = props;
  return (
    <div
      className={`${classes.arrow}  ${classes.arrowLeft} ${className}`}
      onClick={onClick}
    >
      <ChevronLeft />
    </div>
  );
};

export default ArrowPrev;
