import React from 'react';
import { ChevronRight } from '@mui/icons-material';

import style from './style';

const ArrowNext = (props) => {
  const classes = style();
  const { className, onClick, disabled } = props;

  return (
    <div
      className={`${classes.arrow} ${classes.arrowRight} ${
        disabled ? classes.arrowDisabled : ''
      } ${className}`}
      onClick={onClick}
    >
      <ChevronRight />
    </div>
  );
};

export default ArrowNext;
