import { makeStyles } from '@mui/styles';

const style = makeStyles((theme: any) => ({
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: '#e9e9e9',
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 9,
    borderRadius: '4px',
    color: '#000',
    fill: '#fff',
  },
  arrowLeft: {
    left: -15,
  },
  arrowRight: {
    left: 'auto',
    right: -15,
  },
  arrowDisabled: {
    fill: 'rgba(255, 255, 255, 0.5)',
  },

  [theme.breakpoints.up('sm')]: {
    arrow: { width: '2.5rem', height: '2.5rem', borderRadius: '8px' },
  },

  [theme.breakpoints.up('md')]: {
    arrowLeft: {
      left: -45,
    },
    arrowRight: {
      left: 'auto',
      right: -45,
    },
  },
}));
export default style;
